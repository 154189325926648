const Header = () => {


    return (
        <>
            <div className="header bg-big">
                <div className="logo logo-big"></div>
            </div>
            <div className="main-position bg-big"></div>
        </>
    );
};

export default Header;